<template>
<!-- 专家回答问题 -->
  <div class="container">
    <div class="form">

      <div class="row row2" style="margin: 12px 0">
        <div class="label">问题名称</div>
        <div class="read">
          {{ form.title }}
        </div>
      </div>

      <div class="row col" style="margin-bottom: 12px">
        <div class="label must">问题回答</div>
        <van-field v-model="form.content" rows="5" autosize type="textarea" />
      </div>
    </div>

    <van-button type="info" block @click="subForm">提交</van-button>
  </div>
</template>
<script>
import { getAskDetail, answer } from '@/api/eventApi'
import wx from 'weixin-jsapi'
export default {
  data() {
    return {
        form: {
          title: '',
          content: '',
          id: ''
        },
        type: ''
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.getQuestion(this.$route.query.id)
  },
  mounted() {},
  methods: {
    getQuestion(id) {
      this.$store.commit('SET_IS_LOADING', true)
      getAskDetail({
        id: id
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        this.form.title = res.data.title
        this.form.desc = res.data.content
      })
    },
    subForm() {
      if(!this.form.content) {
        return this.$toast({
            type: 'warning',
            message: '回答不能为空',
            duration: 1000,
          })
      }
      this.$store.commit('SET_IS_LOADING', true)
      answer({
        content: this.form.content,
        noticeId: this.form.id,
        openid: this.$store.getters.openId,
      }).then(res => {
        this.$store.commit('SET_IS_LOADING', false)
        if(res.code == 200) {
          this.form.content = ''
          this.$toast({
            type: 'success',
            message: '回复成功',
            duration: 1000,
            onClose: () => {
              // this.$router.go(-1)
              wx.closeWindow()
              // WeixinJSBridge.call('closeWindow');
            }
          })
          
        }
      })
    }
  },
}
</script>
<style scoped lang="scss">
// @import '@/assets/css/form.css';
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  font-size: 14px;
}
.row {
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 12px;
  &.row2 {
    align-items: center;
    .label {
    width: 65px;
    flex-shrink: 0;
    }
    .van-field {
      padding: 2px 4px;
    }
  }
  &.col {
    flex-direction: column;
    align-items: start;
    .van-field {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  /* height: 100px; */
}
    .label {
      margin-bottom: 10px;
    }
  }
  .label {
    width: 65px;
    flex-shrink: 0;
  }
  .label.must {
    position: relative;
  }
  .label.must::after {
    content: '*';
    color: #e10602;
    font-size: 18px;
    position: absolute;
    left: -8px;
    top: 1px;
  }
}
.read {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #eeeeee;
}
.form {
  min-height: calc(100vh - 24px - 46px - 56px);
}
.row.start {
  align-items: start;
}
::v-deep .row .van-uploader__upload {
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 5px;
}
.item {
  width: 100%;
  height: 28px;
  line-height: 28px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 5px;
}
.van-field {
  border: 1px solid #eeeeee;
  padding: 5px;
  border-radius: 5px;
}
</style>
